<template>
  <div class="wrap">
    <NavBar :navSelIndex="2"></NavBar>
    <div class="mall-index">
      <!-- 轮播图 -->
      <b-carousel id="carouselExampleControls" :interval="4000" controls indicators background="#ababab">
        <b-carousel-slide v-for="item in banners" v-bind:key="item.banner_path" :img-src="item.banner_path">
        </b-carousel-slide>
        <div class="search-box">
          <div class="search-input-box">
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle search-select" type="button" id="dropdownMenuButton"
                data-toggle="dropdown" aria-expanded="false">
                {{ selectInfo.title }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#" v-for="(item, index) in selectInfoList" :key="index"
                  @click="selectInfo = item">{{ item.title }}</a>
              </div>
            </div>
            <div class="search-split-box">
              <img src="../../../../public/icon/search-split@2x.png" class="search-split" />
            </div>
            <input v-model="keyWords" class="search-input-info" :placeholder="searchPlaceholder" @keyup.enter="search" />

            <div class="search-split-box margin-left-auto">
              <img src="../../../../public/icon/product-distingush@2x.png" class="search-icon" @click="openUploadAdd()" />
            </div>
            <div class="search-split-box">
              <img src="../../../../public/icon/search-split@2x.png" class="search-split" />
            </div>
            <div class="search-split-box margin-right-22" @click="search()">
              <img src="../../../../public/icon/search-icon@2x.png" class="search-icon" />
            </div>
          </div>
          <div :class="openUpload == true ? 'soutu-state-normal' : 'hiddenUpload'">
            <div class="graph-d20-search-layer-choose">

              <div class="general-upload">
                <el-upload class="upload-demo" action="#" :auto-upload="false" ref="upload" :limit="1"
                  :file-list="fileList" :on-change="handleFileUpload">
                  <i class="el-icon-upload"></i>
                  <span>文件上传</span>
                </el-upload>

                <!-- <el-button type="primary" @click="submitupload()">提交</el-button> -->
                <!-- <el-form id="form">
                <input type="file" style="opacity: 0;" onchange="submitImage()">
                <input type="submit" name="submit"/>
             </el-form> -->
              </div>
            </div>
          </div>
          <div class="key-words-box">
            <span class="key-words-title">{{ $t("homeSearchPlaceholder") }}:</span>
            <span class="key-words" v-for="(item, index) in keyWordsList" :key="index" @click="
              keyWords = item;
            search();
            ">{{ item }}</span>
          </div>
        </div>
      </b-carousel>
      <div class="nav-sort">
        <div class="nav-sort-cont">
          <div class="nav-sort-all">
            <div class="nav-sort-all-txt" @click="goAllSort">
              <span>全部商品分类</span>
              <img src="../../../../public/icon/mall/index/xiajiantou.png" alt="">
            </div>
            <div class="classification" v-show="allSortTrue" @mouseenter="allSortItemTrue = true"
              @mouseleave="allSortItemTrue = false" >
              <div class="classification-left">
                <div class="classification-left-item" @mouseenter="secondaryMobile(index)"
                  v-for="(item, index) in labelList" :key="index">
                  <div class="primary-classification" @click="goSearch({ categoryId: item.LABEL_ID })">
                    <img :src="item.IMG_PATH" alt="">
                    <span class="label-name">{{ item.LABEL_NAME }}</span>
                  </div>
                  <div class="secondary-classification">
                    <span @click="goSearch({ categoryId: item.LABEL_ID, categoryTwoId: it.LABEL_ID })" class="label-name"
                      v-for="(it, ind) in item.label2" :key="ind">{{ it.LABEL_NAME }}</span>
                  </div>
                </div>
              </div>
              <div class="classification-right" v-show="allSortItemTrue">
                <div class="secondary-classification" v-for="(item, index) in rightLabelList.list" :key="index">
                  <span @click="goSearch({ categoryId: labelList[rightIndex].LABEL_ID, categoryTwoId: item.LABEL_ID })"
                    class="secondary-title">{{ item.LABEL_NAME }}</span>
                  <div class="Level3-classification">
                    <span
                      @click="goSearch({ categoryId: labelList[rightIndex].LABEL_ID, categoryTwoId: item.LABEL_ID, categoryThreeId: it.LABEL_ID })"
                      class="label-name" v-for="(it, ind) in item.label3" :key="ind">{{ it.LABEL_NAME }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="nav-sort-item" v-for="(item, index) in navSort" :key="index" @click="goNavSort(item)">
            <img :src="item.imgUrl" alt="" />
          </div>
        </div>
      </div>
      <div class="mall-index-cont">
        <b-container class="index-cont" fluid>
          <b-row class="style-cont">
            <b-col class="style-item" v-for="(item, index) in styleList" :key="index" sm="3" md="2" lg="1" xl="1"
              @click="toSearch(item)">
              <img :src="item.IMG_PATH" alt="" />
              <span>{{ item.LABEL_NAME }}</span>
            </b-col>
          </b-row>
          <b-row class="category-cont">
            <b-col class="category-left" sm="12" md="12" lg="12" xl="6">
              <img :src="classificationFigure.img1" alt="" @click="toCashGoods" />
            </b-col>
            <b-col class="category-right" sm="12" md="12" lg="12" xl="6">
              <b-row class="category-right-top">
                <b-col>
                  <img :src="classificationFigure.img2" alt="" @click="toTopSelling" />
                </b-col>
                <b-col class="category-img3">
                  <img :src="classificationFigure.img3" alt="" @click="toNewArrivals" />
                </b-col>
              </b-row>
              <img class="category-right-bottom" :src="classificationFigure.img4" alt="" @click="toActivityArea" />
            </b-col>
          </b-row>
          <!-- <div class="match-title">自主搭配，无限应用到家</div>
          <b-row class="match-cont">
            <b-col class="match-item" v-for="(item, index) in vrList" :key="index" sm="12" md="6" lg="6" xl="4">
              <div class="match-in" @click="to3DDetail(item)">
                <img :src="item.img_path" alt="" />
                <div class="match-txt">
                  <span class="name">{{ item.works_name }}</span>
                  <span class="action">{{ item.hourse_type }} | {{ item.measure_area }} |
                    {{ item.style_name }}</span>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="more-solutions">
            <b-col class="more-txt" sm="12" md="6" lg="6" xl="4" @click="toMoreScheme">查看更多方案</b-col>
          </b-row> -->
        </b-container>
      </div>
      <!-- <div class="package-index-cont">
        <div class="package-title">一键购买，精选主推套餐</div>
        <b-row class="package">
          <b-col class="package-item" @mouseenter="packageDetailsShow = item.setmeal_id"
            @mouseleave="packageDetailsShow = ''" v-for="(item, index) in packageList" :key="index" sm="12" md="6" lg="6"
            xl="2">
            <div class="package-in">
              <img :src="item.lroom_img" alt="" />
              <div class="package-details" v-show="packageDetailsShow == item.setmeal_id">
                <span class="details">{{ item.setmeal_introduce }}</span>
                <span class="preferential-price">- 套餐e家价 -</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div> -->
      <b-container class="furniture" fluid>
        <!-- <b-row class="more-package">
          <b-col class="more-txt" sm="12" md="6" lg="6" xl="4" @click="toMorePackage">查看更多套餐</b-col>
        </b-row> -->
        <div class="furniture-title">臻选家具， 私享生活品质</div>
        <div></div>
        <div v-for="(item, index) in spaceList" :key="index">
          <b-row class="space-top">
            <b-col class="space-name"> {{ item.title }} </b-col>
            <b-col class="space-sort" @click="toChoosingFurniture">
              客厅 | 餐厅 | 卧室 | 书房 | 儿童房 | 查看更多 >>
            </b-col>
          </b-row>
          <b-row class="space-cont" v-if="item.other.length > 0">
            <b-col class="space-first" sm="12" md="12" lg="8" xl="8">
              <div class="space-first-item" @click="toProductDetail(item.first)">
                <img v-if="item.first.img_path" :src="item.first.img_path" alt="" />
                <div class="space-first-details">
                  <span v-if="item.first.goods_name" class="space-first-name">{{
                    item.first.goods_name
                  }}</span>
                  <span v-if="item.first.sale_price" class="space-first-price">￥ {{ item.first.sale_price }}</span>
                </div>
              </div>
            </b-col>
            <b-col class="space-other" sm="6" md="6" lg="6" xl="4" v-for="(it, ind) in item.other" :key="ind">
              <div class="space-other-item" @click="toProductDetail(it)">
                <img :src="it.img_path" alt="" />
                <div class="space-other-details">
                  <span class="space-other-name">{{ it.goods_name }}</span>
                  <span class="space-other-price">￥ {{ it.sale_price }}</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="tasted-bg">
        <b-container class="tasted-cont" fluid>
          <div class="tasted-title">装点未来，你的生活方式</div>
          <b-row class="tasted-in">
            <b-col v-for="(item, index) in tastedData" :key="index" sm="12" md="6" lg="6" xl="3" class="tasted-item">
              <div class="tasted" @click="toProductDetail(item)">
                <img :src="item.file_path" alt="" />
                <span class="tasted-name">{{ item.goods_name }}</span>
                <span class="tasted-price">￥ {{ item.sale_price }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row class="more-tasted">
            <b-col class="more-txt" sm="12" md="6" lg="6" xl="4" @click="toSoftOutfit">查看更多软装</b-col>
          </b-row>
        </b-container>
      </div>
      <!-- <b-container class="Introduction" fluid>
        <div class="Introduction-title">你好，一切从你开始</div>
        <div class="Introduction-cap">名欧国际</div>
        <b-row class="Introduction-cont">
          <b-col class="Introduction-left" sm="12" md="12" lg="6" xl="6">
            <span>名欧国际在全国已入住120+个城市</span>
            <img src="../../../../public/icon/mall/index/map.png" alt="" />
          </b-col>
          <b-col class="Introduction-right" sm="12" md="12" lg="6" xl="6">
            <div class="about">
              <span v-for="(item, index) in aboutList" :key="index">{{
                item
              }}</span>
            </div>
            <div class="big-event">
              <div class="big-event-title">大事件</div>
              <div class="big-event-cont">
                <div class="big-event-img">
                  <img :src="bigEventImg" alt="" />
                </div>
                <div class="big-event-item">
                  <span
                    @mouseenter="bigEventImg = item.NEWS_IMG"
                    v-for="(item, index) in bigEventList"
                    :title="item.NEWS_TITLE"
                    :key="index"
                    @click="toBigEvent(item)"
                    >{{ item.NEWS_TITLE }}</span
                  >
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container> -->
      <b-container class="partners" fluid>
        <div class="partners-title">合作伙伴</div>
        <b-row class="partners-cont">
          <b-col @mouseenter="partnersEnter(index, item)" @mouseleave="partnersLeave(index, item)" class="partners-item"
            v-for="(item, index) in partnersList" :key="index" sm="6" md="4" lg="4" xl="2">
            <img :src="require(`@/../public/icon/mall/index/${item}`)" alt="" />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import dump from "@/util/dump";
// import url from "@/api/config";
export default {
  components: {
    NavBar,
    Footer,
  },
  data() {
    return {
      token: null,
      labelList: [],
      rightLabelList: { list: [] },
      allSortTrue: true,
      allSortItemTrue: false,
      sortId: '',
      rightIndex: 0,
      fileList: [],
      openUpload: 0,
      maxLength: 0,
      navSort: [
        {
          imgUrl: require("../../../../public/icon/mall/index/aa@2x.png"),
          type: 3,
          link: "/choosingFurniture",
        },
        {
          imgUrl: require("../../../../public/icon/mall/index/bb@2x.png"),
          type: 3,
          link: "/softOutfit",
        },
        {
          imgUrl: require("../../../../public/icon/mall/index/cc@2x.png"),
          type: 3,
          link: "/optimizingBrand",
        },
        // {
        //   imgUrl: require("../../../../public/icon/mall/index/dd@2x.png"),
        //   type: 3,
        //   link: "/packageList",
        // },
      ],
      keyWordsList: ["现代客厅", "卧室", "极简"],
      banners: [
        {
          banner_path:
            "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/37700da2-f715-43f7-b39e-f554c0487c90.jpg",
        },
      ],
      selectInfo: {
        title: '搜单品',
        id: 1,
      },
      keyWords: "",
      searchPlaceholder: '搜商品套餐名称、编码',
      selectInfoList: [
        {
          title: '搜单品',
          id: 1,
        },
        // {
        //   title: '搜套餐',
        //   id: 2,
        // }
      ],
      styleList: [], //风格数据
      classificationFigure: {
        //分类图片
        img1: "",
        img2: "",
        img3: "",
        img4: "",
      },
      vrList: [], //720°VR数据
      packageList: [], //套餐数据
      packageDetailsShow: "", //套餐详情显示
      spaceList: [
        //空间产品数据
        {
          title: "极简风格",
          fitst: "",
          other: [],
        },
        {
          title: "轻奢风格",
          fitst: "",
          other: [],
        },
        {
          title: "新中式风格",
          fitst: "",
          other: [],
        },
        {
          title: "现代美式风格",
          fitst: "",
          other: [],
        },
      ],
      tastedData: [], //装点未来数据
      bigEventList: [], //大事件数据
      aboutList: [
        "名欧国际隶属于意大利风尚国际集团，借助于深圳这一国际化都市的设计先导优势，整合全球软装供应链资源，所有产品全部经过臻选、严选、优选，提供站式软装采购平台，开创了全新的S2B2C新零售商业模式。",
        "集团已在家居行业精耕细作10余年，构建了完整的软装产业链。集团拥有数千平米拎包入住共享展厅，自有4万平米花园式软装工厂，4年拎包入住经验。",
        "通过互联网、物联网、大数据、交易平台、云服务等技术，3D云技术+(VR+AR)技术向消费者提供一站式(产品+设计+体验+服务)整体软装配搭及落地服务;实现软装行业的信息化过程，提升整个产业链运营效率，助力装企及传统家居店面转型升级，为其输送优质的供应链产品、转换工具及服务。",
      ], //简介
      bigEventImg: "", //大事件当前展示图片
      partnersList: [
        "wk_grey.png",
        "bl_grey.png",
        "bgy_grey.png",
        "hr_grey.png",
        "zx_grey.png",
        "zs_grey.png",
        "jzy_grey.png",
        "wd_grey.png",
        "ld_grey.png",
        "yzf_grey.png",
        "dy_grey.png",
        "xy_grey.png",
      ], //合作伙伴数据
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getStyleData();
    this.getBannerData();
    this.getVr();
    this.getPackageList();
    this.getSpaceData();
    this.getTastedData();
    this.getNewData();
    this.getIndexBanner();
    this.getLabelList();
  },
  methods: {
    //获取banner
    getIndexBanner() {
      const that = this;
      that.$api.banners({ banner_type: 1 }).then((res) => {
        if (res.status == 100) {
          that.banners = res.data;
        }
      });
    },
    //获取全部商品分类
    getLabelList() {
      const that = this
      that.$api.labelList({ style_type: 3 }).then((res) => {
        if (res.status == 100) {
          that.labelList = res.data
          that.rightLabelList = res.data[0].label2
        }
      })
    },
    //获取风格数据
    getStyleData() {
      const that = this;
      that.$api.indexGetStyle({}).then((res) => {
        if (res.status == 100) {
          that.styleList = res.data;
        }
      });
    },
    //获取分类图片
    getBanner(item) {
      const that = this;
      that.$api.getbanners({ banner_type: item }).then((res) => {
        if (res.status == 100) {
          switch (item) {
            case (item = 11):
              that.classificationFigure.img1 = res.data[0].banner_path;
              break;
            case (item = 12):
              that.classificationFigure.img2 = res.data[0].banner_path;
              break;
            case (item = 13):
              that.classificationFigure.img3 = res.data[0].banner_path;
              break;
            case (item = 14):
              that.classificationFigure.img4 = res.data[0].banner_path;
              break;
          }
        }
      });
    },
    //获取分类图片方法
    getBannerData() {
      const that = this;
      let arr = [11, 12, 13, 14];
      arr.forEach((item) => {
        that.getBanner(item);
      });
    },
    //获取720°VR
    getVr() {
      const that = this;
      that.$api.getVr({}).then((res) => {
        if (res.status == 100) {
          that.vrList = res.data.designers.splice(0, 6);
        }
      });
    },
    //获取套餐数据
    getPackageList() {
      const that = this;
      that.$api.getIndexTaocanList({}).then((res) => {
        if (res.status == 100) {
          that.packageList = res.data.setmeals.splice(0, 6);
        }
      });
    },
    //获取空间产品数据
    getHomeSuperiorsData(item) {
      const that = this;
      that.$api.getHomeSuperiors({ style_type: item }).then((res) => {
        if (res.status == 100) {
          switch (item) {
            case (item = 1):
              that.$set(that.spaceList[1], "first", res.data[0]);
              that.$set(that.spaceList[1], "other", res.data.slice(1, 5));
              break;
            case (item = 2):
              that.$set(that.spaceList[0], "first", res.data[0]);
              that.$set(that.spaceList[0], "other", res.data.slice(1, 5));
              break;
            case (item = 3):
              that.$set(that.spaceList[2], "first", res.data[0]);
              that.$set(that.spaceList[2], "other", res.data.slice(1, 5));
              break;
            case (item = 4):
              that.$set(that.spaceList[3], "first", res.data[0]);
              that.$set(that.spaceList[3], "other", res.data.slice(1, 5));
              break;
          }
        }
      });
    },
    //获取空间数据方法
    getSpaceData() {
      const that = this;
      let arr = [1, 2, 3, 4];
      arr.forEach((item) => {
        that.getHomeSuperiorsData(item);
      });
    },
    //获取装点未来数据
    getTastedData() {
      const that = this;
      that.$api.getTasted({}).then((res) => {
        if (res.status == 100) {
          that.tastedData = res.data.goods.splice(0, 8);
        }
      });
    },
    //获取新闻大事件
    getNewData() {
      const that = this;
      that.$api.getNew({}).then((res) => {
        if (res.status == 100) {
          that.bigEventList = res.data;
          that.bigEventImg = res.data[0].NEWS_IMG;
        }
      });
    },
    //合作伙伴移入事件
    partnersEnter(index, item) {
      const that = this;
      if (item) {
        item = item.replace("_grey", "");
        that.$set(that.partnersList, index, item);
      }
    },
    //合作伙伴移出事件
    partnersLeave(index, item) {
      const that = this;
      if (item) {
        item = item.replace(".", "_grey.");
        that.$set(that.partnersList, index, item);
      }
    },
    // 跳转到现货专区
    toCashGoods() {
      dump.link({
        type: 3,
        link: "/cashGoods",
      });
    },
    // 跳转到热销排行
    toTopSelling() {
      dump.link({
        type: 3,
        link: "/hotSell",
      });
    },
    // 跳转到新品推荐
    toNewArrivals() {
      dump.link({
        type: 3,
        link: "/newProduct",
      });
    },
    // 跳转到活动专区
    toActivityArea() {
      dump.link({
        type: 3,
        link: "/discount"
      })
    },
    to3DDetail(item) {
      item.type = 2;
      item.link = item.three_d_url;
      // item.link = `${url}/mapp/plan/getThreeDimensional?url=${url}&workId=${item.works_id}&token=${this.token}`;
      dump.link(item);
    },
    // 查看更多方案
    toMoreScheme() {
      dump.link({
        type: 3,
        link: "/threeDimensional",
      });
    },
    toMorePackage() {
      dump.link({
        type: 3,
        link: "/packageList",
      });
    },
    toProductDetail(obj) {
      dump.link({
        type: 3,
        link: "/product/details",
        params: {
          good_id: obj.goods_id,
        },
      });
    },
    toSoftOutfit() {
      dump.link({
        type: 3,
        link: "/softOutfit",
      });
    },
    toBigEvent(obj) {
      dump.link({
        type: 2,
        link: obj.NEWS_PATH,
      });
    },
    toChoosingFurniture() {
      dump.link({
        type: 3,
        link: "/choosingFurniture",
      });
    },
    toSearch(obj) {
      dump.link({
        type: 3,
        link: "/search",
        params: {
          keywords: obj.LABEL_NAME
        }
      });
    },
    goNavSort(item) {
      dump.link(item);
    },
    goAllSort() {
      this.allSortTrue = !this.allSortTrue
      // dump.link({
      //   link: "/search",
      //   type: 3,
      // });
    },
    // 处理文件上传操作
    handleFileUpload(file, fileList) {
      this.$refs.upload.clearFiles()
      let length = fileList.length;
      this.maxLength = Math.max(length, this.maxLength)
      console.log(file.raw)
      setTimeout(() => {
        if (length == this.maxLength) {
          var formData = new FormData();
          formData.append("file", file.raw)
          this.$api.ossUpload(formData).then(res => {
            console.log(res.data)
            this.searchImage = res.data
            var d = document.querySelector(".general-upload");
            var e = document.createElement("input")
            // e.href = "http://baidu.com"
            e.style = "display:none"
            e.type = "button"   
            e.onclick = this.handleWindowOpen
            d.appendChild(e)
            e.click()
            // e.trigger('click')
          }).catch((e) => {
            this.$message.error(e.message);
          })
        }
      }, 100)
    },
    handleWindowOpen() {
      dump.link({
            type: 3,
            link: "/searchImg",
            params: {
              searchImage: this.searchImage
            }
          })
    },
    openUploadAdd() {
      if (this.openUpload == false) {
        this.openUpload = true
      } else {
        this.openUpload = false
      }
    },
    search() {
      const that = this;
      if (that.selectInfo.id == 1) {
        //搜索单品
        let data = {
          type: 3,
          link: "/search",
          params: {
            keyWords: that.keyWords
          }
        };
        dump.link(data);
      } else if (that.selectInfo.id == 2) {
        //搜索套餐
        let data = {
          type: 3,
          link: "/packageList",
          params: {
            keyWords: that.keyWords
          }
        };
        dump.link(data);
      }
    },
    goSearch(item) {
      let data = {
        type: 3,
        link: "/search",
        params: {}
      }
      if (item.categoryId) {
        data.params.categoryId = item.categoryId
      }
      if (item.categoryTwoId) {
        data.params.categoryTwoId = item.categoryTwoId
      }
      if (item.categoryThreeId) {
        data.params.categoryThreeId = item.categoryThreeId
      }
      dump.link(data);
    },
    secondaryMobile(index) {
      const that = this
      // console.log('that.labelList[index].label2',that.labelList[index].label2)
      that.rightIndex = index;
      that.$set(that.rightLabelList, 'list', that.labelList[index].label2)
    }
  },
};
</script>
<style lang="scss" scoped>
.soutu-state-normal {
  margin: auto;
  padding: 24px;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
  border-radius: 12px;
  font-size: 13px;
  color: #9195A3;
  width: 52.3rem;

  .graph-d20-search-layer-choose {
    position: relative;
    width: 78px;
    height: 30px;
    line-height: 31px;
    text-align: center;
    color: #fff;
    margin: 16px auto 24px;
    background: #4e6ef2;
    border-radius: 6px;

    /* .general-upload {
      top: 0;
      position: absolute;
    } */
  }
}

.hiddenUpload {
  display: none;
}

@import "@/view/mall/index/index";</style>